<template>
  <div :style="{ width: dimensions, height: dimensions }">
    <v-img
      v-if="logo"
      :src="logo"
      :width="dimensions"
      :height="dimensions"
    ></v-img>
    <v-img v-else :width="dimensions" :height="dimensions">
      <template #placeholder>
        <div
          class="d-flex align-center justify-center fill-height rounded-circle"
          style="background-color: #f0f0f0"
        >
          <span style="color: black; font-size: 0.8rem">
            <!-- only take the first 4 chars -->
            {{
              symbol.substring(
                0,
                symbol.indexOf(".") > -1 ? symbol.indexOf(".") : 4,
              )
            }}
          </span>
        </div>
      </template>
    </v-img>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
  logo: string | null
  symbol: string
  dimensions?: number
}>()

const dimensions = computed(() => props.dimensions ?? 64)
</script>
